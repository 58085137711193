import React, { useState, useEffect } from "react";
import "../../assets/styles/dashboard.css";
import googleLogo from "../../assets/images/google.png";
import facebookLogo from "../../assets/images/facebook.png";
import twitterLogo from "../../assets/images/twitter.png";
import appleLogo from "../../assets/images/apple.png";
import microsoftLogo from "../../assets/images/microsoft.png";
import yahooLogo from "../../assets/images/yahoo.png";
import { signIn, auth } from "../../config/authentication";
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
    const [isSignedIn, setIsSignedIn] = useState(null);
    const [showformpopup, setshowformpopup] = useState(false);
    const [showlogin, setshowlogin] = useState(true);
    const [showregister, setshowregister] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [rpassword, setrPassword] = useState(null);
    const [resetpass, setresetpass] = useState(false);
    const [loginshowPassword, setloginShowPassword] = useState(false);
    const [registershowPassword, setregisterShowPassword] = useState(false);
    const [registerrshowPassword, setregisterrShowPassword] = useState(false);

    useEffect(() => {
        function initFirebaseAuth() {
            onAuthStateChanged(getAuth(), authStateObserver);
        }

        function authStateObserver(user) {
            if (user && user.emailVerified) {
                if (isSignedIn === false || isSignedIn === null) {
                    setIsSignedIn(true);
                }
            } else {
                if (isSignedIn === true || isSignedIn === null) {
                    setIsSignedIn(false);
                }
            }
        }
        initFirebaseAuth();
    }, [isSignedIn]);

    const clearfields = () => {
        setEmail(null);
        setPassword(null);
        setrPassword(null);
        setloginShowPassword(false);
        setregisterShowPassword(false);
        setregisterrShowPassword(false);
    }

    const emailLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            if (!user.emailVerified) {
                await auth.signOut();
                toast.error('Please verify your email before logging in. A verification link was sent to your email.');
            }
        } catch (error) {
            if (error.code === 'auth/invalid-credential') {
                toast.error('Please check your Email-id or password.');
            } else {
                toast.error(error.message);
            }
            console.log(error)
        }
    }

    const emailregister = async (e) => {
        e.preventDefault();
        try {
            if (password !== rpassword) {
                throw new Error("Password and Re-password are not same.")
            }
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(userCredential.user);
            toast.info("A verification email has been sent to your email address. Please verify your email before logging in.");
        } catch (error) {
            if (error.code === 'auth/weak-password') {
                toast.error('Password should be at least 6 characters.');
            }
            else if (error.code === 'auth/email-already-in-use') {
                toast.error('Email-id is already in use.');
            }
            else if (error.code === 'auth/invalid-credential') {
                toast.error('Please check your Email-id or password.');
            }
            else {
                toast.error(error.message);
            }
            console.log(error)
        }
    }

    const resetpassword = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            toast.info('Password reset email sent! Check your inbox.');
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                toast.error("No account found with this email address.");
            } else {
                toast.error(error.message);
            }
            console.log(error)
        }
    }

    return (
        <>
            {showformpopup && (
                <div className="table-form-modal">
                    <div className="table-form-modal-content" style={{ width: '25rem' }}>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: '10px' }}>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => { setshowformpopup(false); setresetpass(false); clearfields(); }}></button>
                        </div>
                        {resetpass ? (<form onSubmit={resetpassword}>
                            <div className="text-center mb-5">
                                <h4>Reset your password</h4>
                            </div>

                            <p className="text-center mb-5">If the email you enter matches an account, we'll send a reset code to:</p>

                            {/* <!-- Email input --> */}
                            <div data-mdb-input-init className="form-floating mb-4">
                                <input type="email" id="resetemail" value={email} className="form-control" onChange={(e) => setEmail(e.target.value)} required />
                                <label className="form-label" htmlFor="resetemail">Email</label>
                            </div>

                            <ul className="nav nav-pills nav-justified mt-5 mb-3" id="ex1" role="tablist" >
                                <li className="nav-item" role="presentation">
                                    <button type="button" className="nav-link" id="tab-login" data-mdb-pill-init role="tab"
                                        aria-controls="pills-login" onClick={() => { setresetpass(false); setshowlogin(true); setshowregister(false); clearfields() }}><i className="fa-solid fa-arrow-left-long"></i>&nbsp;Back</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="submit" className="nav-link" id="tab-register" data-mdb-pill-init role="tab"
                                        aria-controls="pills-register">Submit</button>
                                </li>
                            </ul>
                        </form>
                        ) : (
                            <>
                                <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist" >
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${showlogin ? 'active' : ''}`} id="tab-login" data-mdb-pill-init role="tab"
                                            aria-controls="pills-login" aria-selected="true" onClick={() => { setshowlogin(true); setshowregister(false); clearfields() }}>Login</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${showregister ? 'active' : ''}`} id="tab-register" data-mdb-pill-init role="tab"
                                            aria-controls="pills-register" aria-selected="false" onClick={() => { setshowlogin(false); setshowregister(true); clearfields() }}>Register</button>
                                    </li>
                                </ul>

                                <div className="tab-content">
                                    {/* <div className="tab-pane fade" id="pills-login" role="tabpanel" aria-labelledby="tab-login"> */}
                                    {
                                        showlogin ? (
                                            <form onSubmit={emailLogin}>
                                                <div className="text-center mb-3">
                                                    <p>Sign in with:</p>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('google')}>
                                                        <img className="login-logo" src={googleLogo} alt="Google Logo" />
                                                    </button>
                                                    {/* <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('apple')}>
                                                        <img className="login-logo" src={appleLogo} alt="Apple Logo" />
                                                    </button>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('facebook')}>
                                                        <img className="login-logo" src={facebookLogo} alt="Facebook Logo" />
                                                    </button>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('twitter')}>
                                                        <img className="login-logo" src={twitterLogo} alt="Twitter Logo" />
                                                    </button>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('microsoft')}>
                                                        <img className="login-logo" src={microsoftLogo} alt="Microsoft Logo" />
                                                    </button>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('yahoo')}>
                                                        <img className="login-logo" src={yahooLogo} alt="Yahoo Logo" />
                                                    </button> */}
                                                </div>

                                                <p className="text-center">or:</p>

                                                {/* <!-- Email input --> */}
                                                <div data-mdb-input-init className="form-floating mb-4">
                                                    <input type="email" id="loginName" value={email} className="form-control" onChange={(e) => setEmail(e.target.value)} required />
                                                    <label className="form-label" for="loginName">Email</label>
                                                </div>

                                                {/* <!-- Password input --> */}
                                                <div data-mdb-input-init className="form-floating mb-4">
                                                    <input type={loginshowPassword ? 'text' : 'password'} id="loginPassword" value={password} className="form-control" onChange={(e) => setPassword(e.target.value)} required />
                                                    <label className="form-label" for="loginPassword">Password</label>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                        onClick={() => setloginShowPassword((prevState) => !prevState)}
                                                        style={{ position: 'absolute', right: '10px', top: '10px' }}
                                                    >
                                                        {loginshowPassword ? 'Hide' : 'Show'}
                                                    </button>
                                                </div>

                                                {/* <!-- 2 column grid layout --> */}
                                                <div className="row mb-4">
                                                    <div className="col-md-6 d-flex justify-content-center">
                                                        {/* <!-- Checkbox --> */}
                                                        <div className="form-check mb-3 mb-md-0">
                                                            <input className="form-check-input" type="checkbox" value="" id="loginCheck" defaultChecked />
                                                            <label className="form-check-label" for="loginCheck"> Remember me </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 d-flex justify-content-center">
                                                        <button
                                                            onClick={() => setresetpass(true)}
                                                            style={{
                                                                background: 'none',
                                                                border: 'none',
                                                                color: 'blue',
                                                                textDecoration: 'underline',
                                                                cursor: 'pointer',
                                                                padding: 0
                                                            }}
                                                        >
                                                            Forgot password?
                                                        </button>
                                                    </div>

                                                </div>

                                                {/* <!-- Submit button --> */}
                                                <button type="submit" data-mdb-button-init data-mdb-ripple-init className="btn btn-primary btn-block mb-4 d-grid gap-2 col-6 mx-auto">Sign in</button>

                                                {/* <!-- Register buttons --> */}
                                                <div className="text-center">
                                                    <p>Not a member? <span style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { setshowlogin(false); setshowregister(true); clearfields() }}>Register</span></p>
                                                </div>
                                            </form>
                                        ) : (
                                            null
                                        )
                                    }

                                    {
                                        showregister ? (
                                            <form onSubmit={emailregister}>
                                                <div className="text-center mb-3">
                                                    <p>Sign up with:</p>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('google')}>
                                                        <img className="login-logo" src={googleLogo} alt="Google Logo" />
                                                    </button>
                                                    {/* <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('apple')}>
                                                        <img className="login-logo" src={appleLogo} alt="Apple Logo" />
                                                    </button>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('facebook')}>
                                                        <img className="login-logo" src={facebookLogo} alt="Facebook Logo" />
                                                    </button>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('twitter')}>
                                                        <img className="login-logo" src={twitterLogo} alt="Twitter Logo" />
                                                    </button>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('microsoft')}>
                                                        <img className="login-logo" src={microsoftLogo} alt="Microsoft Logo" />
                                                    </button>
                                                    <button type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-link btn-floating btn-sm" onClick={() => signIn('yahoo')}>
                                                        <img className="login-logo" src={yahooLogo} alt="Yahoo Logo" />
                                                    </button> */}
                                                </div>

                                                <p className="text-center">or:</p>


                                                {/* <!-- Username input --> */}
                                                {/* <div data-mdb-input-init className="form-floating mb-4">
                                        <input type="text" id="registerUsername" className="form-control" />
                                        <label className="form-label" for="registerUsername">Username</label>
                                    </div> */}

                                                {/* <!-- Email input --> */}
                                                <div data-mdb-input-init className="form-floating mb-4">
                                                    <input type="email" id="registerEmail" value={email} className="form-control" onChange={(e) => setEmail(e.target.value)} required />
                                                    <label className="form-label" for="registerEmail">Email</label>
                                                </div>

                                                {/* <!-- Password input --> */}
                                                <div data-mdb-input-init className="form-floating mb-4">
                                                    <input type={registershowPassword ? 'text' : 'password'} id="registerPassword" value={password} className="form-control" onChange={(e) => setPassword(e.target.value)} required />
                                                    <label className="form-label" for="registerPassword">Password</label>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                        onClick={() => setregisterShowPassword((prevState) => !prevState)}
                                                        style={{ position: 'absolute', right: '10px', top: '10px' }}
                                                    >
                                                        {registershowPassword ? 'Hide' : 'Show'}
                                                    </button>
                                                </div>

                                                {/* <!-- Repeat Password input --> */}
                                                <div data-mdb-input-init className="form-floating mb-4">
                                                    <input type={registerrshowPassword ? 'text' : 'password'} id="registerRepeatPassword" value={rpassword} className="form-control" onChange={(e) => setrPassword(e.target.value)} required />
                                                    <label className="form-label" for="registerRepeatPassword">Repeat password</label>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                        onClick={() => setregisterrShowPassword((prevState) => !prevState)}
                                                        style={{ position: 'absolute', right: '10px', top: '10px' }}
                                                    >
                                                        {registerrshowPassword ? 'Hide' : 'Show'}
                                                    </button>
                                                </div>

                                                {/* <!-- Submit button --> */}
                                                <button type="submit" data-mdb-button-init data-mdb-ripple-init className="btn btn-primary btn-block mb-4 d-grid gap-2 col-6 mx-auto">Sign up</button>

                                                {/* <!-- Register buttons --> */}
                                                <div className="text-center">
                                                    <p>Already a member? <span style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { setshowlogin(true); setshowregister(false); clearfields() }}>Login</span></p>
                                                </div>
                                            </form>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </>
                        )}

                    </div>
                </div>
            )}

            {!isSignedIn ? (
                <div role="button" className="login-div" onClick={() => setshowformpopup(true)}>
                    <div style={{ marginRight: '10px' }}>
                        <i className="fa-solid fa-user"></i>
                    </div>
                    <p className="login-text">Login/Register</p>
                </div>
            ) : (
                <Navigate to="/dashboard/page" />
            )}
        </>
    )
}

export default Login;